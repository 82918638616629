export default {
  "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's make good more conspicuous!"])},
  "downloadKarma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the Karma Plus app and start helping people by increasing your karma"])},
  "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus for Android"])},
  "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus for IPhone / IPad"])},
  "karmaPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus"])},
  "howWeMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we make the world a better place"])},
  "weAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a group of caring people who believe that the world can be made better and kinder with technology and an idea that will bring us together. Therefore, we created the Karma Plus application in which we have the opportunity to help each other."])},
  "ourIdeaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our idea is to create a society of people for whom kindness and help to your neighbor is a lifestyle."])},
  "ourIdeaBased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our idea is based on a person's natural need for mutual assistance and for a comfortable life in society. We create a community that is always ready to support and not shy about asking for help."])},
  "missions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Missions"])},
  "makeSociety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make society better and more united"])},
  "socialRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We form a social rating"])},
  "increaseLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We increase the level of mutual assistance"])},
  "howMeMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we make the world a better place"])},
  "rateUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate users!"])},
  "applicationAbility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The application has the ability to raise or lower users' karma. Just take into account, when lowering a person's karma, you lower karma and yourself."])},
  "helpPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help people and ask for help yourself!"])},
  "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the app, you can see users next to you who need your help right now. You can also leave ads yourself, and your request will not be ignored."])},
  "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate!"])},
  "youCan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can donate to any charitable foundation placed on the application. All funds are official."])},
  "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participate in the ranking of users!"])},
  "positiveActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive actions form your Karma score. The higher the karma, the higher you are in the ranking of your city, country and world."])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the app and join the community of the future!"])}
}