<template>
  <div class="language">
    <div class="language__block" @click="open = !open">
      <img
        src="../assets/icons/language.svg"
        class="language__icon"
        alt=""
      />
      <div class="language__text">{{ $i18n.locale == 'ru' ? 'RU' : 'EN' }}</div>
      <img
        src="../assets/icons/arrow-down.svg"
        :class="{'language__arrow_revert': open}"
        class="language__arrow"
        alt=""
      />
    </div>
    <div class="language__open" v-if="open">
      <div
        class="language__option"
        @click="set('ru')"
        >RU Русский</div
      >
      <div
        class="language__option"
        @click="set('en')"
        >EN English</div
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods: {
    set(value) {
      this.$i18n.locale = value;
      this.open = !this.open;
    },
  },
};
</script>

<style scoped>
.language {
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
  padding: 15px 10.5px;
  z-index: 100;
}
.language__block {
  display: flex;
  cursor: pointer;
}
.language__text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: 0px 7px;
}
.language__arrow_revert {
  transform: rotate(180deg);
}
.language__open {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 10px #7983FF;
  right: 5px;
  top: 40px;
  min-width: 130px;
}
.language__option {
  cursor: pointer;
  padding: 14px 15px;
}
</style>
