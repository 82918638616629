<template>
  <div class='main'>
    <div class='main__header'>
      <div class="main__header_arrows_wrapper">
        <div class="main__header_arrows"></div>
        <div class="main__header_arrows_blur"></div>
      </div>
      <div class='main__header_logo'>
      </div>
      <change-language />
      <div class='main__header_title'>{{ $t('do') }}</div>
      <div class='main__header_buttons'>
        <div class='main__header_buttons_label'>{{ $t('downloadKarma') }}</div>
        <div class="main__header_buttons_container">
          <button class='main__header_buttons_container_button'><span class="android" style="background: white;"></span> <span class="button_text">{{ $t('android') }}</span></button>
          <button class='main__header_buttons_container_button'><span class="IOS" style="background: white;"></span><span class="button_text">{{ $t('ios') }}</span></button>
        </div>
      </div>
      <!--<img class="main__header_backgroundHeaderMini" src="./assets/backgroundHeaderMini.png"/>-->
      <!--<div class='main__header_blur_bottom'></div>-->
      <!--<div class='main_header_treangel_one'></div>-->
    </div>
    <div class="main__header_miniScreen">
      <div class='main__header_buttons'>
        <div class='main__header_buttons_label'>{{ $t('downloadKarma') }}</div>
        <div class="main__header_buttons_container">
          <button class='main__header_buttons_container_button'><span class="android" style="background: white;"></span> <span class="button_text">{{ $t('android') }}</span></button>
          <button class='main__header_buttons_container_button'><span class="IOS" style="background: white;"></span><span class="button_text">{{ $t('ios') }}</span></button>
        </div>
      </div>
    </div>
    <div class='main__phone'>
      <img src="./assets/phone.png"/>
    </div>
    <div class='main__about'>
      <!--<div class="main__about_white"></div>-->
      <div class='main__about_container'>
        <div class='main__about_container_title'>Karma Plus</div>
        <div class='main__about_container_desciption'>
          <p>{{ $t('weAre') }}</p>
          <p>{{ $t('ourIdeaCreate') }}</p>
          <p>{{ $t('ourIdeaBased') }}</p>
        </div>
      </div>
    </div>
    <div class='main__photos'>
      <div class='main__photos_phone'></div>
      <div class='main__photos_logo'></div>
    </div>
    <div class='main__mission'>
      <div class='main__mission_title'>{{ $t('missions') }}</div>
      <div class='main__mission_container'>
        <div class='main__mission_container_box'>
          <img src="./assets/icons/group-circle-icon.svg" />
          <div v-html="$t('makeSociety')" />
        </div>
        <div class='main__mission_container_box'>
          <img src="./assets/icons/group-rate-icon.svg" />
          <div>{{ $t('socialRating') }}</div>
        </div>
        <div class='main__mission_container_box'>
          <img src="./assets/icons/handshake-icon.svg" />
          <div>{{ $t('increaseLevel') }}</div>
        </div>
      </div>
    </div>
    <div class='main__advantages'>
      <div class='main__advantages_title' v-html="$t('howWeMake')" />
      <div class="main__advatages_container">
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">{{ $t('rateUsers') }}</div>
            <div class="main__advatages_container_box_descrition">{{ $t('applicationAbility') }}</div>
          </div>
          <div class="main__advatages_container_box_img_box right">
            <img src="./assets/plus.jpg"/>
          </div>
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_img_box">
            <img src="./assets/radar.jpg"/>
          </div>
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">{{ $t('helpPeople') }}</div>
            <div class="main__advatages_container_box_descrition">{{ $t('inApp') }}</div>
          </div>
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">{{ $t('donate') }}</div>
            <div class="main__advatages_container_box_descrition">{{ $t('youCan') }}</div>
          </div>
          <div class="main__advatages_container_box_img_box right">
            <img src="./assets/help.jpg"/>
          </div> 
        </div>
        <div class="main__advatages_container_box">
          <div class="main__advatages_container_box_img_box">
            <img src="./assets/rating.jpg"/>
          </div>
          <div class="main__advatages_container_box_text_box">
            <div class="main__advatages_container_box_title">{{ $t('participate') }}</div>
            <div class="main__advatages_container_box_descrition">{{ $t('positiveActions') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main__footer">
      <div class="main__footer_box">
        <div class='main__footer_logo'></div>
        <div class="main__footer_label">{{ $t('download') }}</div>
        <div class='main__footer_buttons'>
          <button class='main__footer_button'><span class="android" style="background: black;"></span> <span class="button_text">{{ $t('android') }}</span></button>
          <button class='main__footer_button'><span class="IOS" style="background: black;"></span><span class="button_text">{{ $t('ios') }}</span></button>
        </div>
      </div>
      <div class="main__footer_info">
        <span>© Karma Plus 2022</span>
        <span style="font-size: 25px;">18+</span>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeLanguage from './components/ChangeLanguage.vue';
export default {
  name: 'App',
  components: {ChangeLanguage},
  mounted(){
    const observerPhone = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting){
          entry.target.classList.add('animation');
          return;
        }
      })
    });

    observerPhone.observe(document.querySelector('.main__photos_phone'));

    const observerShowUp = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting)
          entry.target.classList.add('show');
      })
    })

    observerShowUp.observe(document.querySelector('.main__mission_container'));

    const observeAdvatagesLeft = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting)
          entry.target.classList.add('showLeft');
      })
    })
    const observeAdvatagesRight = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting)
          entry.target.classList.add('showRight');
      })
    })
    let i = 0;
    document.querySelectorAll('.main__advatages_container_box').forEach((element) => {
      if (element) {
        console.log(i%2==0);
        if(i%2==0)
          observeAdvatagesLeft.observe(element);
        else
          observeAdvatagesRight.observe(element);
        i++;
      }
    })
  const wrapper = document.getElementsByClassName('main__header_arrows_wrapper')[0]; 
  wrapper.addEventListener('mousemove', (e) => {
    console.log(e);
    const {clientWidth, clientHeight} = e.target; // 
    const middleW = clientWidth/ 2;
    const middleH = clientHeight/ 2;
    wrapper.style = `transform: perspective(3000px) rotateX(${ e.clientY < middleH ? Math.abs(middleH - e.clientY) / middleH * -4 : (e.clientY - middleH)/middleH * 4}deg)  rotateY(${ e.clientX < middleW ? Math.abs(middleW - e.clientX) / middleW * -4 : (e.clientX - middleW)/middleW * 4}deg) scale3d(1, 1, 1); will-change: transform`;
  })
  },
}
</script>

<style>
</style>
