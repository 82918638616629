export default {
  "do": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделаем добро заметнее"])},
  "downloadKarma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачай приложение Karma Plus и начни помогать людям, повышая свою карму"])},
  "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus для Android"])},
  "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus для IPhone / IPad"])},
  "karmaPlus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karma Plus"])},
  "howWeMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как мы делаем мир лучше"])},
  "weAre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы группа неравнодушных людей, которые верят, что мир можно сделать лучше и добрее с помощью технологий и идеи объединяющей нас. Поэтому мы создали приложение Karma Plus в котором есть возможность помогать друг другу."])},
  "ourIdeaCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша идея в том, чтобы создать общество людей, для которых доброта и помощь ближнему — это стиль жизни."])},
  "ourIdeaBased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В основе нашей идеи лежит естественная потребность человека к взаимопомощи и к комфортной жизни в социуме. Мы создаём сообщество, всегда готовых поддержать и не стесняются попросить о помощи."])},
  "missions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссии приложения"])},
  "makeSociety": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делать общество лучше и более сплочённым"])},
  "socialRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формируем социальный рейтинг"])},
  "increaseLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повышаем уровень взаимопомощи"])},
  "rateUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценивай пользователей!"])},
  "applicationAbility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В приложении есть возможность повышать или понижать карму пользователей. Только учитывайте, при понижении кармы человека, вы понижаете карму и себе."])},
  "helpPeople": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помогай людям и проси о помощи сам!"])},
  "inApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В приложении можно увидеть пользователей рядом с тобой, которым прямо сейчас нужна твоя помощь. Также ты сам можешь оставлять объявления, и твоя просьба не останется без внимания."])},
  "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Делай пожертвования!"])},
  "youCan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можешь сделать пожертвование в любой благотворительный фонд, размещенный в приложении. Все фонды являются официальными."])},
  "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участвуй в рейтинге пользователей!"])},
  "positiveActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положительные действия формируют твой показатель Кармы. Чем выше карма, тем выше находишься в рейтинге своего города, страны и мира."])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачай приложение и вступи в сообщество будущего!"])}
}